<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="pb-2">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <e-store-combo
            v-model="filters.storeId"
            :required="false"
          />
        </b-col>

        <b-col md="4">
          <FormulateInput
            id="competence"
            v-model="filters.competence"
            v-mask="['##-####']"
            :instruction="$t('Digite o mês e ano. Ex: 02-2022')"
            :label="$t('Competência')"
            :placeholder="$t('mm-aaaa')"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="fiscal-files-table"
        show-empty
        responsive
        striped
        class="fiscal-files-select-row bordered"
        :tbody-tr-class="rowClass"
        :fields="fields"
        :items="fiscalFiles"
        :empty-text="getEmptyTableMessage($tc('INVOICE_FILES.NAME'))"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @row-clicked="onSeclectRow"
        @sort-changed="getData"
      >
        <template #head(check)="">
          <p class="ml-1">
            <e-instructions
              :title="$t('Download de Múltiplos arquivos')"
              :instruction="
                $t(
                  'Selecione os registros e depois vá no botão inferior direto da tela para ver as opções para download.'
                )
              "
            />
          </p>
        </template>

        <template #cell(check)="row">
          <FormulateInput
            v-model="row.item.rowChecked"
            type="e-checkbox"
            @change="(val) => onCheckRow(val, row.item)"
          />
        </template>

        <template #cell(active)="row">
          <e-status-badge
            :status="row.item.active"
            type="yesNo"
          />
        </template>

        <template #cell(reason)="row">
          <e-status-badge
            v-if="row.item.reason"
            :status="row.item.reason"
            :options="reasonOptions()"
          />
          <span v-else> - </span>
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            is-active
            :show-activate="false"
            :show-deactivate="row.item.active"
            :show-update="false"
            :show-delete="false"
            :buttons="[
              {
                icon: 'download',
                variant: 'primary',
                title: $t('Baixar arquivo'),
                event: 'download-file',
              },
            ]"
            @download-file="onDownloadFile(row)"
            @deactivate="onDeactivateCompetence(row)"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="fiscal-files-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="hasBulkPayment"
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :fixed-tooltip="true"
      :actions="fabActions"
      @clean-selections="onCleanSelects"
      @download-selections="onDownloadSelectedFiles"
      @click="() => {}"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import Fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'
import { downloader, financialDomain, formulateValidation, loadingOverlay } from '@/mixins'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EInstructions from '@/views/components/EInstructions.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'

export default {
  components: {
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    Fab,
    BRow,
    BCol,
    BPagination,
    BTable,
    EFilters,
    EGridActions,
    EStoreCombo,
    EInstructions,
    EStatusBadge,
  },

  mixins: [formulateValidation, financialDomain, downloader, loadingOverlay],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/fiscalFiles', [
      'filters',
      'paging',
      'sorting',
      'fiscalFiles',
      'selectedFiles',
    ]),

    hasBulkPayment() {
      return this.selectedFiles.length > 0
    },

    countBulkPayment() {
      return this.selectedFiles.length
    },

    fields() {
      return [
        {
          label: this.$t('Múltiplos arquivos'),
          key: 'check',
          tdClass: 'pl-2 justify-content-center align-items-center',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Loja'),
          key: 'store.id',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '380px' },
          formatter: (val, index, item) => this.$options.filters.storeName(item.store),
          sortable: true,
        },
        {
          label: this.$t('Competência'),
          key: 'competenceDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Tipo'),
          key: 'reason',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          formatter: val => this.$t(val),
        },
        {
          label: this.$t('Data geração arquivo'),
          key: 'fileDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '180px' },
          formatter: (val, index, item) => this.$options.filters.datetime(item.fileDate, '-'),
        },
      ]
    },

    fabActions() {
      return [
        {
          name: 'clean-selections',
          icon: 'backspace',
          color: 'red',
          tooltip: `${this.$t('Limpar seleções')} (${this.countBulkPayment})`,
          hide: false,
        },
        {
          name: 'download-selections',
          icon: 'download',
          color: 'green',
          tooltip: `${this.$t('Download das seleções')} (${this.countBulkPayment})`,
          hide: false,
        },
      ].filter(act => !act.hide)
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/invoice/fiscalFiles', [
      'fetchFiscalFiles',
      'resetFilters',
      'setPageSize',
      'setCurrentPage',
      'cleanSelectedFiles',
      'addSelectedFiles',
      'removeSelectedFiles',
      'deactivateCompetence',
    ]),
    ...mapActions('pages/invoice/invoice/invoiceMaintain', {
      stInvoiceDownload: 'invoiceDownload',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchFiscalFiles()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async onSortTable() {
      await new Promise(resolve => setTimeout(() => resolve(), 5))
      await this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    reasonOptions() {
      return [
        { value: 'FINAL', label: this.$t('Final'), style: { 'background-color': '#28c76f' } },
        { value: 'PARTIAL', label: this.$t('Parcial'), style: { 'background-color': '#ffc107' } },
      ]
    },

    async onDownloadFile(row) {
      try {
        this.showLoadingOverlayV2({
          text: this.$t('Baixando'),
          cancelable: true,
        })
        const { item } = row

        const data = await this.stInvoiceDownload({ pathUrl: item.url })

        const nameFile = `Arquivos_Fiscais_${row.item.store.document.replace(/\D/g, '')}_${
          row.item.competenceDate
        }`

        this.forceFileDownloadFromBase64(data.responsePayload, nameFile, 'zip')
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async onDownloadSelectedFiles() {
      try {
        this.showLoadingOverlayV2({
          text: this.$t('Baixando'),
          cancelable: true,
        })
        const downloadsPromise = this.selectedFiles.map(async item => {
          const data = await this.stInvoiceDownload({ pathUrl: item.url })
          const nameFile = `Arquivos_Fiscais_${item.store.document.replace(/\D/g, '')}_${
            item.competenceDate
          }`
          this.forceFileDownloadFromBase64(data.responsePayload, nameFile, 'zip')
        })

        await Promise.all(downloadsPromise)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async onDeactivateCompetence(row) {
      const { item } = row
      try {
        const confirm = await this.confirm({
          text: this.$t('Após a inativação, esta competência terá que ser gerada novamente.'),
        })

        if (!confirm) return

        await this.$http.patch(`/api/invoices/competence-files/deactivate/${item.id}`)
        this.showSuccess({ message: this.$t('Inativado com sucesso') })
        this.getData()
      } catch (error) {
        this.showError({ error })
      }
    },

    async onCleanSelects() {
      const confirmed = await this.confirm({
        text: this.$t('Os arquivos selecionados serão desmarcados'),
      })
      if (!confirmed) return

      this.cleanSelectedFiles()
      this.getData()
    },

    async onSeclectRow(item) {
      const checkValue = !item.rowChecked
      // eslint-disable-next-line no-param-reassign
      item.rowChecked = checkValue
      this.onCheckRow(checkValue, item)
    },

    async onCheckRow(value, item) {
      try {
        if (value) {
          await this.addSelectedFiles(item)
        } else {
          await this.removeSelectedFiles(item)
        }
      } catch (error) {
        this.showWarning({ message: error.message })
        // eslint-disable-next-line no-param-reassign
        item.rowChecked = !value
      }
    },

    rowClass(item, type) {
      let clazz = item?.rowChecked ? 'selected-row' : ''
      if (item && type === 'row') {
        if (!item.paymentDate) {
          clazz += ' payment-pending'
        }
      }
      return clazz
    },
  },
}
</script>

<style lang="scss">
.fiscal-files-select-row {
  & table tbody .payment-pending td {
    cursor: pointer !important;
  }
}
</style>
