var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pb-2" },
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: { required: false },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["##-####"],
                        expression: "['##-####']",
                      },
                    ],
                    attrs: {
                      id: "competence",
                      instruction: _vm.$t("Digite o mês e ano. Ex: 02-2022"),
                      label: _vm.$t("Competência"),
                      placeholder: _vm.$t("mm-aaaa"),
                    },
                    model: {
                      value: _vm.filters.competence,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "competence", $$v)
                      },
                      expression: "filters.competence",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "fiscal-files-select-row bordered",
            attrs: {
              id: "fiscal-files-table",
              "show-empty": "",
              responsive: "",
              striped: "",
              "tbody-tr-class": _vm.rowClass,
              fields: _vm.fields,
              items: _vm.fiscalFiles,
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("INVOICE_FILES.NAME")
              ),
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "row-clicked": _vm.onSeclectRow,
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "head(check)",
                fn: function () {
                  return [
                    _c(
                      "p",
                      { staticClass: "ml-1" },
                      [
                        _c("e-instructions", {
                          attrs: {
                            title: _vm.$t("Download de Múltiplos arquivos"),
                            instruction: _vm.$t(
                              "Selecione os registros e depois vá no botão inferior direto da tela para ver as opções para download."
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(check)",
                fn: function (row) {
                  return [
                    _c("FormulateInput", {
                      attrs: { type: "e-checkbox" },
                      on: {
                        change: function (val) {
                          return _vm.onCheckRow(val, row.item)
                        },
                      },
                      model: {
                        value: row.item.rowChecked,
                        callback: function ($$v) {
                          _vm.$set(row.item, "rowChecked", $$v)
                        },
                        expression: "row.item.rowChecked",
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(reason)",
                fn: function (row) {
                  return [
                    row.item.reason
                      ? _c("e-status-badge", {
                          attrs: {
                            status: row.item.reason,
                            options: _vm.reasonOptions(),
                          },
                        })
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": "",
                        "show-activate": false,
                        "show-deactivate": row.item.active,
                        "show-update": false,
                        "show-delete": false,
                        buttons: [
                          {
                            icon: "download",
                            variant: "primary",
                            title: _vm.$t("Baixar arquivo"),
                            event: "download-file",
                          },
                        ],
                      },
                      on: {
                        "download-file": function ($event) {
                          return _vm.onDownloadFile(row)
                        },
                        deactivate: function ($event) {
                          return _vm.onDeactivateCompetence(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "fiscal-files-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasBulkPayment
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              "fixed-tooltip": true,
              actions: _vm.fabActions,
            },
            on: {
              "clean-selections": _vm.onCleanSelects,
              "download-selections": _vm.onDownloadSelectedFiles,
              click: function () {},
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }